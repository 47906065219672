.footer-subscribe-container {
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  width: 60%;
  padding: 2rem 0;
}

.footer-subscribe-inputs {
  margin: 20px 20px;
}
